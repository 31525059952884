.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  padding: 0;
  box-sizing: border-box;
}

/* Ensure html and body take up the full height */
html, body {
  height: 100%;
  background-color: #121212; /* Dark background */
}

#page-container {
  min-height: 100%;
  background-color: #121212;
  color: white;
  zoom: 96%;
}

#page-container > * {
  flex: 1;
}

#title{
  font-size: x-large;
  margin-left: 100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-width: 1250px;
}

#notice{
  font-size: large;
  margin-left: 100px;
  margin-top: -20px;
}

#account-detail-container {
  font-size: large;
  background-color: #121212;
  min-width: 250px;
}

#trade-data-container {
  margin-left: 65px;
  display: flex;
  align-items: flex-start;
  background-color: #121212;
}

/* Stock plot */
#plot-container {
  margin: 0 auto;
  margin-left: 1100px;
  justify-content: left;
  background-color: #121212;
}

/* Loading notice */
#loading-notice {
  width: 900px;
  text-align: center;
}


/* Trade markers*/
#trade-marker {
  width: 500px;
  margin-left: 75px;
  overflow-x: auto;
  align-self: end;
  background-color: #121212;
}

.marker-details {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #121212;
}

.marker-details h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.marker-details p {
  margin: 0;
  margin-bottom: 8px;
  font-size: 1rem;
  line-height: 1.4;
}

.marker-details p strong {
  font-weight: bold;
}

.marker-details p:last-child {
  margin-bottom: 0;
}

/* Chart legend */
.chart-descriptions {
  width: 100%;
  margin-top: 20px;
  padding-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #121212;
}

.chart-descriptions h3 {
  margin-top: -3px;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.chart-descriptions p {
  font-size: 14px;
  margin-bottom: -5px;
}

.description-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.color-box-filled {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.color-box {
  width: 25px;
  height: 8px;
  position: relative;
  margin-right: 10px;
}

.color-box::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 3px dashed;
}

.green::before {
  border-color: #00FF30;
}

.red::before {
  border-color: #FF0000;
}

.description-text {
  font-size: 14px;
  line-height: 20px;
}

/* Refresh button*/
.refresh-button {
  background-color: white;
  color: black;
  font-weight: bold;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin-left: 0px;
  margin-top: 25px;
}

.refresh-button:hover {
  background-color: #b3b3b3;
}

.refresh-button:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 3px rgba(90, 90, 90, 0.281); /* Custom focus outline */
}